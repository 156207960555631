<template>
  <component :is="hdtemplate" :hd="hd" :goodList="goodList" :myhdinfo="myhdinfo" v-if="hdtemplate" ref="theme"/>
</template>

<script>
  
  import {GetVideoExternal} from '../services/services'
export default {
  name:'videorelay',
  props: {
    hd:Object,
    myhdinfo:Object,
    goodList:Array
  },
  components: {},
  data() {
    return {
      hdtemplate:null
    }
  },
  created(){
  },
  computed:{
  
  },
  mounted() {
    this.hdtemplate = () => import(`./components/${this.hd.template}.vue`)
  },
  methods: {
  
  }
}
</script>
<style lang="less">

</style>

